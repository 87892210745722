var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record-table" },
    [
      !_vm.dragValue
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "time" },
                [
                  _c("svg", { staticClass: "iconpark-icon" }, [
                    _c("use", { attrs: { href: "#rongqi" } }),
                  ]),
                  _c("a-range-picker", {
                    attrs: {
                      "allow-clear": _vm.clear,
                      "value-format": "YYYY-MM-DD",
                      separator: "~",
                      ranges: _vm.ranges,
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.recordTime,
                      callback: function ($$v) {
                        _vm.recordTime = $$v
                      },
                      expression: "recordTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "record-table-heard" },
                [
                  _c(
                    "div",
                    { staticClass: "record-table-heard-left" },
                    [
                      _c(
                        "appForm",
                        [
                          _c(
                            "template",
                            { slot: "customButton" },
                            [
                              _c(
                                "a-dropdown",
                                {
                                  attrs: { type: "primary" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "overlay",
                                        fn: function () {
                                          return [
                                            _c(
                                              "a-menu",
                                              _vm._l(
                                                _vm.functionList,
                                                function (item, index) {
                                                  return _c(
                                                    "a-menu-item",
                                                    {
                                                      key: index,
                                                      on: { click: _vm.add },
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    514366687
                                  ),
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        prefix: "add-one",
                                        suffix: "left",
                                      },
                                    },
                                    [_vm._v(" 添加请假记录 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "deleteBtn",
                          attrs: {
                            prefix: "reduce-one",
                            disabled:
                              _vm.selectedRowKeys.length === 0 ? true : false,
                          },
                          on: { click: _vm.openDeleteBox },
                        },
                        [_vm._v(" 批量删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c("appForm", {
                    ref: "appForm",
                    staticClass: "btn",
                    attrs: {
                      "to-list": _vm.formList,
                      "search-placeholder": "请搜索姓名/工号",
                      "screen-roster-list": _vm.screenRecordList,
                    },
                    on: {
                      getSearchCallback: _vm.getSearchCallback,
                      screenRosterParams: _vm.screenRosterParams,
                      exportFileList: _vm.exportFileList,
                    },
                  }),
                ],
                1
              ),
              _c("custom-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  "row-key": "vacationApplyId",
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    columnWidth: 30,
                    onChange: _vm.onSelectChange,
                    getCheckboxProps: function (record) {
                      return {
                        props: {
                          disabled: record.operateType === "1",
                        },
                      }
                    },
                  },
                  "selection-mode": _vm.selectionMode,
                  pagination: {
                    current: _vm.pager.pageNum,
                    pageSize: _vm.pager.pageSize,
                    total: _vm.total,
                    showTotal: function (total) {
                      return "共 " + total + " 条数据"
                    },
                    showQuickJumper: true,
                    showSizeChanger: true,
                  },
                },
                on: { change: _vm.loadDataList },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
                      },
                    },
                    {
                      key: "operateType",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "div",
                            {
                              attrs: {
                                title: _vm.getCodeContent(
                                  scope.record.operateType,
                                  _vm.operate_type
                                ),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCodeContent(
                                    scope.record.operateType,
                                    _vm.operate_type
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "staffName",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("CbTableAvater", {
                            staticClass: "staffName",
                            attrs: {
                              "table-name": scope.record.staffName,
                              "on-job-id": scope.record.onJobId,
                              "staff-id": scope.record.staffId,
                              title: scope.record.staffName,
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("div", { staticClass: "flex-align-center" }, [
                            _c(
                              "div",
                              { staticClass: "more-handle" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "link",
                                      disabled: scope.record.operateType == "1",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.record)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  759360867
                ),
              }),
              _c(
                "Modal",
                {
                  attrs: {
                    closable: "",
                    "overflow-auto": "",
                    visible: _vm.addRecord,
                    title: _vm.drawerTitle,
                    size: "normal",
                    "z-index": 8,
                  },
                  on: {
                    "after-close": _vm.afterVisibleChange,
                    cancel: _vm.onClose,
                    ok: _vm.onSubmin,
                  },
                },
                [
                  _c("p", { staticClass: "tips" }, [
                    _vm._v("特别说明：如请假时间有跨月，请按月进行拆分"),
                  ]),
                  _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "onJobId",
                          attrs: { label: "员工姓名", prop: "onJobId" },
                        },
                        [
                          _c("PerTreeSelect", {
                            attrs: {
                              disabled: _vm.drawerTitle !== "添加请假记录",
                              placeholder: "请选择员工姓名",
                            },
                            on: { getPerTreeValue: _vm.getPerTreeValue },
                            model: {
                              value: _vm.form.onJobId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "onJobId", $$v)
                              },
                              expression: "form.onJobId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "vacationTypeId",
                          attrs: { label: "假期类型", prop: "vacationTypeId" },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择假期类型" },
                              on: { change: _vm.getVacationType },
                              model: {
                                value: _vm.form.vacationTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vacationTypeId", $$v)
                                },
                                expression: "form.vacationTypeId",
                              },
                            },
                            _vm._l(_vm.rankData, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.vacationTypeId,
                                  attrs: { value: item.vacationTypeId },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.vacationTypeName) + " "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "startDate",
                          attrs: { label: "开始时间", prop: "startDate" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "add-time" },
                            [
                              _c("a-date-picker", {
                                style:
                                  "width:" +
                                  (_vm.timeUnit === "D" ? "100%" : "60%"),
                                attrs: {
                                  "disabled-date": _vm.disabledStartDate,
                                  "value-format": "YYYY-MM-DD",
                                },
                                on: { change: _vm.timeChange },
                                model: {
                                  value: _vm.form.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startDate", $$v)
                                  },
                                  expression: "form.startDate",
                                },
                              }),
                              _vm.timeUnit === "H"
                                ? _c("a-time-picker", {
                                    staticStyle: { width: "35%" },
                                    attrs: {
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                    },
                                    on: { change: _vm.timeChange },
                                    model: {
                                      value: _vm.form.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startTime", $$v)
                                      },
                                      expression: "form.startTime",
                                    },
                                  })
                                : _vm._e(),
                              _vm.timeUnit === "B"
                                ? _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "35%" },
                                      attrs: { placeholder: "请选择时间" },
                                      on: { change: _vm.timeChange },
                                      model: {
                                        value: _vm.form.startTime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "startTime", $$v)
                                        },
                                        expression: "form.startTime",
                                      },
                                    },
                                    _vm._l(_vm.halfDay_type, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.value,
                                          attrs: { value: item.value },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "endDate",
                          attrs: { label: "结束时间", prop: "endDate" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "add-time" },
                            [
                              _c("a-date-picker", {
                                style:
                                  "width:" +
                                  (_vm.timeUnit === "D" ? "100%" : "60%"),
                                attrs: {
                                  "disabled-date": _vm.disabledEndDate,
                                  "value-format": "YYYY-MM-DD",
                                },
                                on: { change: _vm.timeChange },
                                model: {
                                  value: _vm.form.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endDate", $$v)
                                  },
                                  expression: "form.endDate",
                                },
                              }),
                              _vm.timeUnit === "H"
                                ? _c("a-time-picker", {
                                    staticStyle: { width: "35%" },
                                    attrs: {
                                      format: "HH:mm",
                                      "value-format": "HH:mm",
                                    },
                                    on: { change: _vm.timeChange },
                                    model: {
                                      value: _vm.form.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endTime", $$v)
                                      },
                                      expression: "form.endTime",
                                    },
                                  })
                                : _vm._e(),
                              _vm.timeUnit === "B"
                                ? _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "35%" },
                                      attrs: { placeholder: "请选择时间" },
                                      on: { change: _vm.timeChange },
                                      model: {
                                        value: _vm.form.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "endTime", $$v)
                                        },
                                        expression: "form.endTime",
                                      },
                                    },
                                    _vm._l(_vm.halfDay_type, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.value,
                                          attrs: { value: item.value },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { ref: "shift", attrs: { label: "当前班次" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("shiftName")(_vm.formText.shiftList)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { ref: "time", attrs: { label: "时长" } },
                        [_c("p", [_vm._v(_vm._s(_vm.formText.interval))])]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "applyReason",
                          attrs: { label: "请假原因", prop: "applyReason" },
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: "请输入请假原因",
                              "max-length": 300,
                              "auto-size": { minRows: 4, maxRows: 6 },
                            },
                            model: {
                              value: _vm.form.applyReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "applyReason", $$v)
                              },
                              expression: "form.applyReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: { title: _vm.importTitle, "import-template": "vacation" },
            on: { toggleValue: _vm.toggleValue },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }