var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record-table" },
    [
      !_vm.dragValue
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "time" },
                [
                  _c("svg", { staticClass: "iconpark-icon" }, [
                    _c("use", { attrs: { href: "#rongqi" } }),
                  ]),
                  _c("a-range-picker", {
                    attrs: {
                      "allow-clear": _vm.clear,
                      "value-format": "YYYY-MM-DD",
                      separator: "~",
                      ranges: _vm.ranges,
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.recordTime,
                      callback: function ($$v) {
                        _vm.recordTime = $$v
                      },
                      expression: "recordTime",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "record-table-heard" }, [
                _c(
                  "div",
                  { staticClass: "heard record-table-heard-left" },
                  [
                    _c(
                      "appForm",
                      [
                        _c(
                          "template",
                          { slot: "customButton" },
                          [
                            _c(
                              "a-dropdown",
                              {
                                attrs: { type: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "overlay",
                                      fn: function () {
                                        return [
                                          _c(
                                            "a-menu",
                                            _vm._l(
                                              _vm.functionList,
                                              function (item, index) {
                                                return _c(
                                                  "a-menu-item",
                                                  {
                                                    key: index,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.add(item)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4072988620
                                ),
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      prefix: "add-one",
                                      suffix: "left",
                                    },
                                  },
                                  [_vm._v(" 添加打卡记录 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "deleteBtn",
                        attrs: {
                          prefix: "reduce-one",
                          disabled:
                            _vm.selectedRowKeys.length === 0 ? true : false,
                        },
                        on: { click: _vm.openDeleteBox },
                      },
                      [_vm._v(" 批量删除 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("appForm", {
                      ref: "appForm",
                      attrs: {
                        "to-list": _vm.formList,
                        "search-placeholder": "请搜索姓名/工号",
                        "screen-roster-list": _vm.screenRecordList,
                      },
                      on: {
                        getSearchCallback: _vm.getSearchCallback,
                        screenRosterParams: _vm.screenRosterParams,
                        exportFileList: _vm.exportFileList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("custom-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  "row-key": "staffClockId",
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    columnWidth: 30,
                    onChange: _vm.onSelectChange,
                    getCheckboxProps: function (record) {
                      return {
                        props: {
                          disabled: !["1", "2", "5"].includes(
                            record.punchSourceType
                          ),
                        },
                      }
                    },
                  },
                  pagination: {
                    current: _vm.pager.pageNum,
                    pageSize: _vm.pager.pageSize,
                    total: _vm.total,
                    showTotal: function (total) {
                      return "共 " + total + " 条数据"
                    },
                    showQuickJumper: true,
                    showSizeChanger: true,
                  },
                  "selection-mode": _vm.selectionMode,
                },
                on: { change: _vm.haedChang },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
                      },
                    },
                    {
                      key: "punchSourceType",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.getCodeContent(
                                  scope.record.punchSourceType,
                                  _vm.punch_source
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "punchResultType",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.getCodeContent(
                                  scope.record.punchResultType,
                                  _vm.punch_result
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "staffName",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("CbTableAvater", {
                            attrs: {
                              "table-name": scope.record.staffName,
                              "on-job-id": scope.record.onJobId,
                              "staff-id": scope.record.staffId,
                              title: scope.record.staffName,
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "photo",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("span", [
                            scope.record.clockPicId
                              ? _c("img", {
                                  staticClass: "imgBox",
                                  attrs: {
                                    src:
                                      _vm.basePath +
                                      "/hrsaas-storage/image/thumbnail?id=" +
                                      scope.record.clockPicId +
                                      "&width=30&height=30",
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.preview(scope)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                disabled: !["1", "2", "5"].includes(
                                  scope.record.punchSourceType
                                ),
                                type: "link",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2236039150
                ),
              }),
              _c(
                "Modal",
                {
                  attrs: {
                    "z-index": 8,
                    closable: true,
                    visible: _vm.addRecord,
                    title:
                      _vm.addName === "add" ? "添加打卡记录" : "编辑打卡时间",
                    size: "normal",
                  },
                  on: {
                    "after-close": _vm.afterVisibleChange,
                    cancel: _vm.onClose,
                    ok: _vm.onSubmin,
                    enterPress: _vm.onSubmin,
                  },
                },
                [
                  _c("k-form-build", {
                    ref: "KFB",
                    attrs: {
                      value: _vm.addIndividuallyList,
                      "text-display": true,
                    },
                    on: { change: _vm.changeForm },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: { title: _vm.importTitle, "import-template": "clock" },
            on: { toggleValue: _vm.toggleValue },
          }),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.previewVisible,
            title: "打卡图片查看",
            closable: "",
            keyboard: "",
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("div", {
            staticClass: "model_img",
            style:
              "background-image:url(" +
              _vm.basePath +
              "/hrsaas-storage/image/thumbnail?id=" +
              _vm.previewImageId +
              "&width=200&height=200)",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }